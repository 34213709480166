
import React, { useState, useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol } from 'mdb-react-ui-kit';
import Modal from 'react-modal';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const GalleryButton = ({ images, modalTitle }) => {
  const [modalShow, setModalShow] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  useEffect(() => {
    Modal.setAppElement('#root');
  }, []);

  const handleOpenModal = (index) => {
    setSelectedImageIndex(index);
    setModalShow(true);
  };

  const handleCloseModal = () => {
    setModalShow(false);
  };

  return (
    <MDBContainer>
      <MDBRow>
        <MDBCol md="6">
          <button onClick={() => handleOpenModal(0)} className="gallery-button">Gallery</button>
        </MDBCol>
      </MDBRow>

      <Modal
        isOpen={modalShow}
        onRequestClose={handleCloseModal}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',
            maxWidth: '1000px',
            zIndex: '9999',
            paddingBottom: '70px',

          },
          overlay: {
            zIndex: '9998',
          }
        }}
      >
        <h2 className='locationKS'>{modalTitle}</h2>
        <hr class="custom-line"></hr>
        <button className='btn-modal' onClick={handleCloseModal}>Close</button>
        
        <Carousel
          selectedItem={selectedImageIndex}
          onChange={(index) => setSelectedImageIndex(index)}
          showThumbs={false}
          dynamicHeight={true}
          infiniteLoop={true}
          useKeyboardArrows={true}
        >
          {images.map((image, index) => (
            <div key={index}>
              <img
                src={image.src}
                alt={`Slide ${index}`}
                style={{ maxHeight: '700px', objectFit: 'contain' }}
              />
            </div>
          ))}
        </Carousel>
      </Modal>
    </MDBContainer>
  );
};

export default GalleryButton;

// import React, { useState } from 'react';
// import { Modal, Carousel } from 'react-bootstrap';


// const GalleryButton = ({ images, modalTitle }) => {
//   const [modalShow, setModalShow] = useState(false);
//   const [selectedImageIndex, setSelectedImageIndex] = useState(0);

//   const handleOpenModal = (index) => {
//     setSelectedImageIndex(index);
//     setModalShow(true);
//   };

//   const handleCloseModal = () => {
//     setModalShow(false);
//   };

//   return (
//     <div>
//       <button onClick={() => handleOpenModal(0)} className="gallery-button">Gallery</button>
      
//       <Modal show={modalShow} onHide={handleCloseModal}>
//         <Modal.Header closeButton>
//           <Modal.Title>{modalTitle}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <Carousel activeIndex={selectedImageIndex} onSelect={(index) => setSelectedImageIndex(index)}>
//             {images.map((image, index) => (
//               <Carousel.Item key={index}>
//                 <img
//                   className="d-block w-100"
//                   src={image.src}
//                   alt={`Slide ${index}`}
//                   style={{ maxHeight: '700px', objectFit: 'contain' }}
//                 />
//               </Carousel.Item>
//             ))}
//           </Carousel>
//         </Modal.Body>
//         <Modal.Footer>
//           <button className="close" onClick={handleCloseModal}>Close</button>
//         </Modal.Footer>
//       </Modal>
//     </div>
//   );
// };

// export default GalleryButton;