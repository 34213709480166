// import React from 'react';

// function Banner() {
//   return (
//     <div className='banner'>
//     <video autoPlay muted loop playsInline id='banner-video'>
//     <source src='img/banner-small.mp4' type='video/mp4' media='(max-width: 767px)' />
//   <source src='img/banner-big.mp4' type='video/mp4' media='(min-width: 768px)' />
//       <source src='img/banner.mp4' type='video/mp4' />
//       <source src='img/banner.webm' type='video/webm' />
//       Your browser does not support HTML5 video.
//     </video>
//     <div className="logos">
//         <div className='logo-container'>
//           <img src='img/Logo-top.png' className='logo' alt='Kiwi Krane Station' />
//         </div>
//         <div className='social-media' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//           <a href='https://www.instagram.com/kranestation/' target='_blank' rel='noopener noreferrer'>
//             <img
//               src='img/insta.png'
//               className='socialmedia'
//               alt='Kiwi Krane Station Instagram'
//               style={{ maxWidth: '50px', height: 'auto', marginRight: '5px' }}
//               id='insta-img'
//             />
//           </a>
//           <a href='https://www.facebook.com/kranestationnewzealand' target='_blank' rel='noopener noreferrer'>
//             <img
//               src='img/face.png'
//               className='socialmedia'
//               alt='Kiwi Krane Station Facebook'
//               style={{ maxWidth: '50px', height: 'auto', marginLeft: '5px' }}
//               id='face-img'
//             />
//           </a>
//         </div>

//         </div>

//         <div className='containerBtn'>
//           <a href="#Services">
//             <button className='rounded-button'>WELCOME</button>
//           </a>
          
//         </div>



    

//     </div>
//   );
// }

// export default Banner;






import React from 'react';

function Banner() {
  return (
    <div className='banner'>
      <video autoPlay muted loop playsInline id='banner-video'>
        <source src='img/banner-small.mp4' type='video/mp4' media='(max-width: 767px)' />
        <source src='img/banner-big.mp4' type='video/mp4' media='(min-width: 768px)' />
        <source src='img/banner.mp4' type='video/mp4' />
        <source src='img/banner.webm' type='video/webm' />
        Your browser does not support HTML5 video.
      </video>
      <div className="logos">
        <div className='logo-container'>
          <img 
            src='img/Logo-top.png' 
            className='logo' 
            alt='Kiwi Krane Station Claw Machine Logo'  // Alt text for the main claw machine logo
          />
        </div>
        <div className='social-media' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <a href='https://www.instagram.com/kranestation/' target='_blank' rel='noopener noreferrer'>
            <img
              src='img/insta.png'
              className='socialmedia'
              alt='Instagram Claw Machine Prizes at Kiwi Krane Station'  // Alt text for Instagram icon
              style={{ maxWidth: '50px', height: 'auto', marginRight: '5px' }}
              id='insta-img'
            />
          </a>
          <a href='https://www.facebook.com/kranestationnewzealand' target='_blank' rel='noopener noreferrer'>
            <img
              src='img/face.png'
              className='socialmedia'
              alt='Facebook Claw Machine Fun at Kiwi Krane Station'  // Alt text for Facebook icon
              style={{ maxWidth: '50px', height: 'auto', marginLeft: '5px' }}
              id='face-img'
            />
          </a>
        </div>
      </div>

      {/* Título oculto pero accesible para SEO */}
      <div style={{ textAlign: 'center' }}>

</div>




      <div className='containerBtn'>
        <a href="#Intro">
          <button className='rounded-button'>WELCOME</button>
        </a>
      </div>
    </div>
  );
}

export default Banner;
