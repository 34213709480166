import React from 'react';
import './App.css';


import { Fade } from "react-awesome-reveal";

function ImagenConTitulo(props) {
  return (
    <div className="imagen-con-titulo">
      <img src={props.imagenUrl} alt={props.titulo} />
      <h2 className="titulo-services">
        {/* <a href={props.pdfUrl} download={props.pdfFileName}> */}
          {props.titulo}
        {/* </a> */}
      </h2>
      <p className='servicesTxt'>{props.text}</p>
    </div>
  );
}

function Services() {
  const ourServices = [
    {
      imagenUrl: "img/ourservices-1.png",
      titulo: "Claw Machines",
      text: "A huge variety of plush to win from the Claw Machine. Test your skill and win your favourite toy."
    },
    {
      imagenUrl: "img/ourservices-2.png",
      titulo: "Instant Prize",
      text: "Are your reflexes quick enough to win the top prizes in the Claw Machine? Challenge your friends."
    },
    {
      imagenUrl: "img/ourservices-3.png",
      titulo: "Blocks Store",
      text: "Win Claw Machine prizes connecting blocks to add to your collection. How many can you win?" 
    }
  ];

  return (
    <section className="ourServices" id="Services">
        <div className='Our-Services' id="Services">OUR SERVICES</div>
      <Fade triggerOnce cascade delay={300} >
        <div className="imag-container">
        
          {ourServices.map((item, index) => (
            <ImagenConTitulo
              key={index}
              imagenUrl={item.imagenUrl}
              titulo={item.titulo}
              text={item.text}
            />
          ))}
        </div>
      </Fade>
    </section>
  );
}

export default Services;
