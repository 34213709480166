import React from 'react';
import { Link } from 'react-router-dom';
// import Header from './header';
import { Route } from 'react-router-dom';
// import ScrollToTopOnMount from './ScrollToTopOnMount'; 
import Banner from './banner';
import Services from './services';
import Stores from './stores';
import Collage from './collage';
import Contact from './contact';
import { Fade } from 'react-bootstrap';
import Intro from './intro';
// import Map from './map';

function Home() {
  return (
    <div>
      {/* <Route path="/#Collage" component={ScrollToTopOnMount} /> */}

        <Banner />
<Intro />
       <div id="Services">
  <Services />
</div>
        <Stores />
        <Collage />
        <Contact />

        {/* <Map /> */}
        {/* <Photogallery /> */}
    </div>
  );
}

export default Home;

// <div className="Prizes">
// <h1 className="collage-title">COLLAGE</h1>

