import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './App.css';
import Map from './map';
import GalleryButton from './galleryButton'; 


function CentroConTabla(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className='location'>
      <div className='location-container'>
        <div className='table'>
          <div className="table-content">
            <div className="table-left">
              <h1 className='title-location'>{props.titulo}</h1>
              <p className='address'>{props.texto}</p>
              <p className='address2'>{props.texto2}</p>
              <h4 className='titleLocation'>{props.titulo2}</h4>
              <table className='table-Opendata'>
                <tbody>
                  {props.tablaData.map((fila, index) => (
                    <tr className='tbody' key={index}>
                      <td>{fila.columna1}</td>
                      <td>{fila.columna2}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              
            </div>
            <div className="table-right">
              {props.mapK}
              <GalleryButton images={props.images} modalTitle={props.modalTitle} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const Compo1 = {
  titulo: "Ormiston Town Centre",
  imagenUrl: "img/stores.png",
  texto: 'Kranestation is located in 240 Ormiston Road, Flat Bush, Auckland 2016',
  texto2: 'Located in the Ormiston Town Centre Food Court by the HOYTS escalators.',
  titulo2: "Opening Hours:",
  tablaData: [
    { columna1: "Monday", columna2: "10:00am - 22:00pm" },
    { columna1: "Tuesday", columna2: "10:00am - 22:00pm" },
    { columna1: "Wednesday", columna2: "10:00am - 22:00pm" },
    { columna1: "Thursday", columna2: "10:00am - 22:00pm" },
    { columna1: "Friday", columna2: "10:00am - 22:00pm" },
    { columna1: "Saturday", columna2: "10:00am - 22:00pm" },
    { columna1: "Sunday", columna2: "10:00am - 22:00pm" },
  ],
  mapK: <Map coordinates={[-36.9661577, 174.9126717]} />,
  images: [
    { src: "/img/Ormiston/img1.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img2.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img3.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img4.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img5.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img6.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img7.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img8.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img9.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img10.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img11.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img12.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img13.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img14.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img15.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img16.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img17.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img18.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img19.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img20.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img21.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img22.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img23.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img24.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img25.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img26.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img27.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img28.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img29.jpg", alt: "play toy crane" },
    { src: "/img/Ormiston/img30.jpg", alt: "play toy crane" },
    
  ],
    modalTitle: "Ormiston Town Centre Gallery"
};

const Compo2 = {
  titulo: "Sylvia Park - HOYTS Cinemas",
  imagenUrl: "img/stores.png",
  texto: "Kranestation is located in Sylvia Park Shopping Centre, 286 Mount Wellington Highway, 1060",
  titulo2: "Opening Hours:",
  tablaData: [
    { columna1: "Monday", columna2: "09:45am - 11:30pm" },
    { columna1: "Tuesday", columna2: "09:45am - 11:30pm" },
    { columna1: "Wednesday", columna2: "09:45am - 11:30pm" },
    { columna1: "Thursday", columna2: "09:45am - 11:30pm" },
    { columna1: "Friday", columna2: "09:45am - 11:30pm" },
    { columna1: "Saturday", columna2: "09:45am - 11:30pm" },
    { columna1: "Sunday", columna2: "09:45am - 11:30pm" },
  ],
  mapK: <Map coordinates={[-36.916724, 174.841475]} />, 
  images: [
    { src: "/img/Hoyts/img1.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img2.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img3.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img4.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img5.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img6.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img7.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img8.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img9.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img10.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img11.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img12.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img13.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img14.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img15.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img16.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img17.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img18.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img19.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img20.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img21.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img22.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img23.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img24.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img25.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img26.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img27.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img28.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img29.jpg", alt: "play toy crane" },
    { src: "/img/Hoyts/img30.jpg", alt: "play toy crane" },    
  ],
   modalTitle: "Sylvia Park HOYTS Cinemas Gallery"
};

const Compo3 = {
  titulo: "Coming Soon",
  imagenUrl: "img/stores.png",
  texto: "Here the address.",
  titulo2: "Opening Hours:",
  tablaData: [
    { columna1: "Monday", columna2: "10:00am - 22:00pm" },
    { columna1: "Tuesday", columna2: "10:00am - 22:00pm" },
    { columna1: "Wednesday", columna2: "10:00am - 22:00pm" },
    { columna1: "Thursday", columna2: "10:00am - 22:00pm" },
    { columna1: "Friday", columna2: "10:00am - 22:00pm" },
    { columna1: "Saturday", columna2: "10:00am - 22:00pm" },
    { columna1: "Sunday", columna2: "10:00am - 22:00pm" },
  ],
  mapK: <Map coordinates={[-36.9231, 174.8451]} />,
  images: [
    { src: "stores-2.png" },
    { src: "stores-3.png" },
    { src: "stores" }
  ],
    modalTitle: "Ormiston Town Centre Gallery"
};

function TableComponent() {
  const { id } = useParams();

  const components = {
    '1': Compo1,
    '2': Compo2,
    '3': Compo3
  };

  const selectedComponent = components[id] || Compo1;

  return (
    <div className='table-component'>
      <CentroConTabla {...selectedComponent} />
    </div>
  );
}

export default TableComponent;