import reat from 'react';


function Intro() {
  return (
    <div className="intro" id="Intro">
  <h1 

    style={{ 
      color: '#009ACD', 
      fontFamily: '"Quicksand-Medium-Bold", sans-serif', 
      fontSize: '40px', 
      fontWeight: 'bold', 
      marginBottom: '35px',
      lineHeight: '1.0' 
    }}
  >
    The Ultimate Claw Machine Experience  <br />
    for Family and Friends
  </h1>
     <h3  style={{ 
      color: '#333', 
      fontFamily: '"Quicksand", sans-serif', 
      fontSize: '20px', 
    //   fontWeight: 'bold', 
      marginBottom: '50px',
      marginLeft: '15px',
       marginRight: '15px'
    }}>
        
        <b>Discover the Best Online Claw Machines at KraneStation!</b> <br />

At KraneStation, experience the thrill of playing claw machines with friends and family! Whether you're a fan of toy machines or love the excitement of winning, our platform offers endless fun and incredible prizes. From adorable collectibles to top-quality toys, each grab is your chance to win big.

Playing claw machines has never been easier or more affordable—get started with just 1 token! Join the fun today and challenge your loved ones to see who can grab the most exciting prizes.
     </h3>
    </div>
  );
}

export default Intro;

