import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link, NavLink } from "react-router-dom"; // Eliminé Switch ya que no se usa en react-router-dom v6
import Home from './home';
import Header from './header';
import Footer from './footer';
import Services from './services';
import Stores from './stores';
import Collage from './collage';
import Contact from './contact';
import Photogallery from './photogallery';
import Location from './claw-machine-location';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/stores" element={<Stores />} /> {/* Corregido el error tipográfico */}
        <Route path="/collage" element={<Collage />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/photogallery" element={<Photogallery />} />
        {/* Aquí usamos element para pasar el componente Location */}
        <Route path="/claw-machine-location/:id" element={<Location />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;