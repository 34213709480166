import React from "react";
import { Link } from 'react-router-dom'; 

function Collage() {
  return (
    <div className="Collage" id="Collage">
      <div className="Collage-home"
        // style={{
        //   width: '400px',
        //   height: '300px',
        //   border: '1px solid #ccc',
        //   display: 'flex',
        //   flexDirection: 'column',
        //   alignItems: 'center',
        //   justifyContent: 'center',
        //   borderRadius: '10px',
        //   marginLeft: 'auto',
        //   marginRight: 'auto',
        //   backgroundColor: 'white',
        // }}
      >
      <h1 className='PhotoGallery-title' style={{ marginBottom: '8px' }}>Photo Gallery</h1>
      <p style={{ textAlign: 'center', padding: '0 20px' }}>
        Explore claw machine fun, prizes, and toy play moments for endless excitement!
      </p>
      <Link to='./photogallery'> 
        <button className="rounded-button2">
          See more pictures
        </button>
      </Link>


      </div>
    </div>
  );
}

export default Collage;

